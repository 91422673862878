<script>
import CustomTable from "../../../../components/tables/custom-table.vue";
import productImportShow from "./product-import-show.vue";
import Status from "../../../../helpers/general/status";

/**
 * Customers Component
 */
export default {
    props: {
      customer_task_id: Number
    },
  components: {
    CustomTable,
    productImportShow,
  },
  data() {
    return {
      showModalId:'product-import-show-modal',
      table:{
        url:'/product-imports',        
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "finished_at", label: "Finished at", sortable: true },
          { 
            key: "process_status",
            label: "Status",
            sortable: true
          },
        ],
        formatted_fields:[
          'process_status'
        ]
      }, 
      showSelectedItemId:null,   
    };
  },
  computed:{
    params(){
      return {customer_task_id: this.customer_task_id};
    }
  },
  methods: {
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    formatter(field, item){
      if(field === 'process_status'){
        const status = Status.getStatus({itemStatus: item?.process_status, type: 'customer_task_import'});
        return status;
      }
      return '';
    }
  }
};
</script>

<template>
  <div class="card h-100">
        <div class="card-body">
            <productImportShow :modal-id="showModalId" :id="showSelectedItemId"></productImportShow>
            <h4 class="card-title mb-4">Sync history</h4>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :formatted-fields="table.formatted_fields"
              :formatter="formatter"
              :show-edit="false"
              :show-change-status="false"
              :show-view="true"             
              :params="params"              
              @show="showViewModal"
              :containerTableClass="'max-h-250'"
              :show-auto-refresh="true"
              ref="productImportShowTable"
            >              
            </CustomTable>
        </div>
    </div>
</template>