<script>
import Modal from "../../../../components/modals/modal-form.vue";
import api from '../../../../helpers/apirest/api';
import productForm from "../forms/product-form.vue";
import productImports from "./product-imports"
import productSchedules from "./product-schedules"
import Vue from "vue";

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return {
            product:null,
            productFormModalId:'product-form-modal-show',          
        }
    },
    components: {
        Modal,
        productForm,
        productImports,
        productSchedules
    },
    computed: {
        modalTilte(){
            return `${this.product?.description}`;
        },
        getSourceDriverImg(){          
            return require(`@/assets/images/drivers/${this.product?.source_driver?.source_driver_type?.slug}.png`) ;
        },
        getTargetDriverImg(){          
            return require(`@/assets/images/drivers/${this.product?.target_driver?.target_driver_type?.slug}.png`) ;
        }
    },
    methods: {
        async getData(){
            if (this.id) {
                const response = await api.get({
                    url: `products/${this.id}`
                });
                this.product = response?.data?.data;
            }
        },
        shown() {
            this.getData();            
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        onSuccess(){            
            this.getData(); 
        },
        editproduct(){
            this.$bvModal.show(this.productFormModalId);
        },
        async runTask(){
            try {

                await Vue.swal({
                title: "Run task now?",
                icon: "info",
                html:  "Are you sure you want to run this task right now?",
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.save({
                        url: `products/${this?.product?.id}/run-task`,
                        successMsg: 'The synchronization task has been created successfully'
                    }).then(()=>{
                        this.$refs.test.$refs.productImportShowTable.refresh();
                    }, ()=>{
                        this.$refs.test.$refs.productImportShowTable.refresh();
                    });
                },
                });
                
            }
            catch (error) {
                console.log(error);
            }
        }
    }
};
</script>

<template>
    <div>
        <productForm :modal-id="productFormModalId" :id="id" @onSuccess="onSuccess"></productForm>
        <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Close'">
            <div class="row" v-if="product">
                <div class="col-6">
                    <div class="card">
                        <div class="card-body">
                        <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
                            <template v-slot:button-content>
                            <i class="mdi mdi-dots-vertical"></i>
                            </template>
                            <!-- item-->
                            <b-dropdown-item @click="editproduct">Edit</b-dropdown-item>
                            <!-- item-->
                            <b-dropdown-item @click="runTask">Sync now</b-dropdown-item>
                        </b-dropdown>

                        <h4 class="card-title mb-4">Connection data</h4>

                        <div class="row">
                            <div class="col-5">
                                <ul class="list-unstyled activity-wid-sm">
                                    <li class="activity-list" v-if="product?.source_driver">
                                        <div class="activity-icon avatar-sm">
                                            <span class="avatar-title bg-soft-primary text-primary rounded-circle">                                        
                                                <img :src="getSourceDriverImg" alt width="35" />
                                            </span>
                                        </div>
                                        <div>
                                        <div>
                                            <h5 class="font-size-13">
                                            {{product?.source_driver?.source_driver_type?.name}}
                                            <small class="text-muted">Source</small>
                                            </h5>
                                        </div>
                                        <div>
                                            <p class="text-muted mb-0">{{product?.source_driver?.name}}</p>
                                        </div>
                                        </div>
                                    </li>
                                    <li class="activity-list">
                                        <div class="activity-icon avatar-sm">
                                            <span class="avatar-title bg-soft-primary text-primary rounded-circle">                                        
                                                <img :src="getTargetDriverImg" alt width="35" />
                                            </span>
                                        </div>
                                        <div>
                                        <div>
                                            <h5 class="font-size-13">
                                            {{product?.target_driver?.target_driver_type?.name}}
                                            <small class="text-muted">Target</small>
                                            </h5>
                                        </div>
                                        <div>
                                            <p class="text-muted mb-0">{{product?.target_driver?.name}}</p>
                                        </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-7">
                                <table class="table table-hover mb-0 table-centered table-nowrap">
                                    <tbody>
                                    <tr>
                                        <th class="text-right">Last successful sync</th>
                                        <td>{{ product?.last_sync }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Success Emails</th>
                                        <td>{{ product?.success_emails }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Error Emails</th>
                                        <td>{{ product?.error_emails }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                    </div>
                    <productSchedules :customer_task_id="id" ref="test"></productSchedules>
                </div>
                <div class="col-6">
                    <productImports :customer_task_id="id" ref="test"></productImports>
                </div>
            </div>
        </Modal>
    </div>    
</template>