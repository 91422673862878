<script>
import Status from "@/helpers/general/status";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            product_tax_status: Status.getList('product_tax_status'),
        }
    },
    components: {        
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12" v-if="value.type === 'simple'">
            <label for="regular_price">Precio normal</label>
            <input
                id="regular_price"
                v-model="value.regular_price"
                type="text"
                class="form-control"
                placeholder="Ingresa el precio regular del producto"
                :class="{ 'is-invalid': v.product.regular_price.$error && submitted }"
            />
        </div>
        <div class="form-group col-12" v-if="value.type === 'simple'">
            <label for="sale_price">Precio rebajado</label>
            <input
                id="sale_price"
                v-model="value.sale_price"
                type="text"
                class="form-control"
                placeholder="Ingresa el precio rebajado del producto"
                :class="{ 'is-invalid': v.product.sale_price.$error && submitted }"
            />
        </div>
        <div class="form-group col-12">
            <label for="tax_status">Estado del impuesto</label>
            <select v-model="value.tax_status" class="form-control"
                :class="{ 'is-invalid': v.product.tax_status.$error && submitted }">
                <option v-for="option in product_tax_status" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div class="form-group col-12">
            <label for="tax_class">Clase de impuesto</label>
            <input
                id="tax_class"
                v-model="value.tax_class"
                type="text"
                class="form-control"
                placeholder="Ingresa la clase de impuesto del producto"
                :class="{ 'is-invalid': v.product.tax_class.$error && submitted }"
            />
        </div>
    </div>
</template>