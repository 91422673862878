<script>
import { required } from "vuelidate/lib/validators";
import Modal from "../../../../components/modals/modal-form.vue";
import QlkTree from "../../../../components/widgets/qlk-tree.vue";
import api from '../../../../helpers/apirest/api';
import Status from "../../../../helpers/general/status";
import QlkMedia from "../../../../components/upload/qlk-media.vue";
import draggable from "vuedraggable";
import GeneralPartial from "./partials/general-partial.vue";
import GeneralTabPartial from "./partials/tabs/general-tab-partial.vue";
import InventoryTabPartial from "./partials/tabs/inventory-tab-partial.vue";
import ShippingTabPartial from "./partials/tabs/shipping-tab-partial.vue";
import AttributeTabPartial from "./partials/tabs/attribute-tab-partial.vue";
import VariationTabPartial from "./partials/tabs/variation-tab-partial.vue";
import StoreTabPartial from "./partials/tabs/store-tab-partial.vue";
import AdvancedTabPartial from "./partials/tabs/advanced-tab-partial.vue";
import AstunTabPartial from "./partials/tabs/astun-tab-partial.vue";

import {
    storeComputed,
    productCategoryComputed
} from "@/state/helpers";

const initialState = () => {
    return {
        submitted: false,
        uploadModalId:'upload-modal',
        product: {
            parent_product_id: null,
            name: null,
            slug: null,
            type: 'simple',
            status: 'publish',
            featured: false,
            catalog_visibility: 'visible',
            description: null,
            short_description: null,
            sku: null,
            regular_price: null,
            sale_price: null,
            date_on_sale_from: null,
            date_on_sale_from_gmt: null,
            date_on_sale_to: null,
            date_on_sale_to_gmt: null,
            virtual: false,
            downloadable: false,
            is_youth_card: false,
            bookable: false,
            download_limit: -1,
            download_expiry: -1,
            external_url: null,
            button_text: null,
            tax_status: 'taxable',
            tax_class: null,
            manage_stock: false,
            stock_quantity: null,
            stock_status: 'instock',
            backorders: 'no',
            sold_individually: false,
            weight: null,
            length: null,
            width: null,
            height: null,
            shipping_class: null,
            reviews_allowed: true,
            purchase_note: null,

            product_categories: [],
            stores: [],
            product_attributes: [],
            product_media: [],
            product_variations: [],
            product_metadata: {
                qlk_commerce_is_forfait:null,
                qlk_commerce_forfait_required_date:null,
                qlk_commerce_product_with_cupon_id: null,

                qlk_commerce_card_product: {
                    id: null,
                    name: null,
                    price: null
                },
                qlk_commerce_insurance_product: {
                    id: null,
                    name: null,
                    price: null
                }
            }
        },
        product_types: Status.getList('product_types'),
        stock_status: Status.getList('stock_status'),
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        QlkTree,
        QlkMedia,
        draggable,
        GeneralPartial,
        GeneralTabPartial,
        InventoryTabPartial,
        ShippingTabPartial,
        AttributeTabPartial,
        VariationTabPartial,
        StoreTabPartial,
        AdvancedTabPartial,
        AstunTabPartial
    },
    computed: {
        ...storeComputed,
        ...productCategoryComputed,
    },
    validations: {
        product: {
            name: { required },
            status: { required },
            featured: {},
            catalog_visibility: {},
            type: { required },
            regular_price: {},
            sale_price: {},
            tax_status: { required },
            tax_class: { },
            virtual: {},
            downloadable: {},
            is_youth_card: {},
            bookable: {},
            product_parent_id: {},
            slug: {},
            description: {},
            display: {},
            sku: {},
            manage_stock: {},
            stock_quantity: {},
            backorders: {},
            stock_status: {},
            sold_individually: {},
            weight: {},
            length: {},
            width: {},
            height: {},
            shipping_class: {},
            purchase_note: {},
            menu_order: {},
            reviews_allowed: {},
            stores: {
                $each: {
                    id: {required},
                    slug: {},
                    menu_order: {},
                }
            },
            product_media: {
                $each: {
                    id: {}
                }
            },
            product_attributes: {
                $each: {
                    name: {required},
                    options: {required},
                    position: {},
                    visible: {},
                    variation: {},
                    default_option: {},
                }
            },
            product_variations: {
                $each: {
                    status: {},
                    regular_price: {},
                    sale_price: {},
                    tax_status: { required },
                    tax_class: { },
                    virtual: {},
                    downloadable: {},
                    description: {},
                    sku: {},
                    manage_stock: {},
                    stock_quantity: {},
                    backorders: {},
                    stock_status: {},
                    weight: {},
                    length: {},
                    width: {},
                    height: {},
                    shipping_class: {},
                }
            }
        }
    },
    methods: {
        wrapProductStores(product) {
            if(product?.stores){
                const stores = product?.stores.map(store => {
                    store.pivot.id = store.pivot.store_id;
                    store.pivot.is_update = true;
                    return store?.pivot;
                });
                product.stores = stores;
            }
        },
        wrapProductCategories(product) {
            if(product?.product_categories){
                const product_categories = product?.product_categories.map(item => {
                    return item?.id
                });
                product.product_categories = product_categories;
            }
        },
        wrapProductMetadata(product) {
            if(product?.product_metadata){
                const product_metadata = this.product.product_metadata;
                product?.product_metadata.forEach(item => {
                    product_metadata[item.key] = item?.value;
                });
                product.product_metadata = product_metadata;
            }
        },
        async shown() {

            this.$store.dispatch("store/loadList");
            this.$store.dispatch("productCategory/loadList", {
                params: {
                    with_recursive_children: 1
                }
            });

            if (this.id) {
                const response = await api.get({
                    url: `products/${this.id}`,
                    config: {
                        withLoading: true
                    }  
                });
                const product = response?.data?.data;
                
                this.wrapProductStores(product);
                this.wrapProductCategories(product);
                this.wrapProductMetadata(product);

                this.product = Object.assign({}, this.product, product);
            }
        },
        
        
        async confirm() {

            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            const data = this.product;
            let order = 0;
            data.product_media = this.product.product_media.map(item => {
                item.order = order++;
                return item;
            });

            await api.save({
                url: 'products',
                id: this.product?.id,
                data: data,
                config: {
                    withLoading: true
                }                
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
        updateCategories(newValues) {
            this.product.product_categories = newValues;
        },
        showUploadModal() {
            this.$bvModal.show(this.uploadModalId);
        },
        handleSelectedImages(images) {
            this.product.product_media = images;
        },
        deleteImage(key){
            this.product.product_media.splice(key, 1);
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de producto'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">
            <div class="col-lg-4 col-md-12 mb-2 product-form-left-column">
                <GeneralPartial v-model="product" :v="$v" :submitted="submitted"></GeneralPartial>
            </div>

            <div class="col-lg-8 col-md-12 mb-2 product-form-right-column">
                <div class="card mb-0">
                    <div class="card-body">
                        <h4 class="card-title form-inline">
                            Detalle
                        </h4>
                        <p class="card-title-desc">
                            Personaliza las caracteristicas del producto
                        </p>
                        <div class="row form-inline-border">
                             <div class="col-12 form-inline">
                                <div class="form-group mr-2">
                                    <label class="mr-2">Tipo</label>
                                    <select
                                        v-model="product.type"
                                        class="form-control"
                                        :class="{ 'is-invalid': $v.product.type.$error && submitted }"
                                    >
                                        <option v-for="option in product_types" v-bind:value="option.id" :key="option.id">
                                            {{ option.label }}
                                        </option>
                                    </select>
                                </div>                       
                                <div class="form-group custom-control custom-checkbox custom-control-right mr-2" v-if="product.type === 'simple'">
                                    <input
                                        type="checkbox"
                                        v-model="product.virtual"
                                        class="custom-control-input"
                                        id="virtual"
                                        :class="{ 'is-invalid': $v.product.virtual.$error && submitted }"
                                    />
                                    <label class="custom-control-label" for="virtual">Virtual</label>
                                </div>
                                <div class="form-group custom-control custom-checkbox custom-control-right mr-2" v-if="product.type === 'simple'">
                                    <input
                                        type="checkbox"
                                        v-model="product.downloadable"
                                        class="custom-control-input"
                                        id="downloadable"
                                        :class="{ 'is-invalid': $v.product.downloadable.$error && submitted }"
                                    />
                                    <label class="custom-control-label" for="downloadable">Descargable</label>
                                </div>
                                <div class="form-group custom-control custom-checkbox custom-control-right mr-2" v-if="product.type === 'simple'">
                                    <input
                                        type="checkbox"
                                        v-model="product.bookable"
                                        class="custom-control-input"
                                        id="bookable"
                                        :class="{ 'is-invalid': $v.product.bookable.$error && submitted }"
                                    />
                                    <label class="custom-control-label" for="bookable">Reservable</label>
                                </div>
                                <div class="form-group custom-control custom-checkbox custom-control-right mr-2" v-if="product.type === 'simple'" title="Marque esta opción si el producto representa la venta del Carné Joven."
                                        v-b-tooltip.hover>
                                    <input
                                        type="checkbox"
                                        v-model="product.is_youth_card"
                                        class="custom-control-input"
                                        id="is_youth_card"
                                        :class="{ 'is-invalid': $v.product.is_youth_card.$error && submitted }"
                                        
                                    />
                                    <label class="custom-control-label" for="is_youth_card">Carné Joven</label>
                                </div>
                             </div>
                        </div>
                        <b-tabs
                            pills
                            vertical
                            nav-class="p-0"
                            nav-wrapper-class="col-sm-3 product-form-tabs-list"
                            content-class="px-2 text-muted"
                            class="product-form-tabs"
                            >
                            <b-tab title="General" active title-item-class="mb-2">
                                <b-card-text>
                                    <GeneralTabPartial v-model="product" :v="$v" :submitted="submitted"></GeneralTabPartial>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Inventario" title-item-class="mb-2">
                                <b-card-text>
                                    <InventoryTabPartial v-model="product" :v="$v" :submitted="submitted"></InventoryTabPartial>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Envío" title-item-class="mb-2" v-if="[ 'simple', 'variable' ].includes(product.type) && !product.virtual">
                                <b-card-text>
                                    <ShippingTabPartial v-model="product" :v="$v" :submitted="submitted"></ShippingTabPartial>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Categorías" title-item-class="mb-2">
                                <p class="text-muted font-13">Selecciona las categorías del producto</p>
                                <b-card-text>
                                    <QlkTree
                                        :items="categoryTree"
                                        :selected="product.product_categories"
                                        @update:selected="updateCategories"
                                    />
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Atributos" title-item-class="mb-2">
                                <b-card-text>
                                    <AttributeTabPartial v-model="product.product_attributes" :product_type="product.type" :v="$v" :submitted="submitted"></AttributeTabPartial>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Variaciones" title-item-class="mb-2" v-if="product.type === 'variable'">
                                <b-card-text>
                                    <VariationTabPartial v-model="product.product_variations" :product_attributes="product.product_attributes" :product_sku="product.sku" :v="$v" :submitted="submitted"></VariationTabPartial>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Imagenes" title-item-class="mb-2">
                                <b-card-text>
                                    <QlkMedia :modal-id="uploadModalId" :single="false" :selected="product.product_media" @onSuccess="handleSelectedImages"/>
                                    
                                    <draggable class="qlk-product-gallery-list" group="tasks" :list="product.product_media">
                                        <div v-for="(item, i) in product.product_media" :key="i" class="qlk-product-gallery-item">
                                            <div class="qlk-product-gallery-item-checkbox">
                                                <i class="ri-close-circle-fill text-danger" @click="deleteImage(i)" v-b-tooltip.hover title="Eliminar"></i>
                                            </div>
                                            <div class="qlk-product-gallery-item-thumbnail">
                                                <img :src="item.url" alt="image">
                                                {{ item.name }}
                                            </div>
                                        </div>
                                    </draggable>
                                    <b-alert
                                        show
                                        variant="secondary"
                                        class="text-center"
                                    >
                                        <a
                                            href="javascript:void(0);"
                                            class="text-success"
                                            @click="showUploadModal()"
                                        >
                                            <i class="mdi mdi-plus-circle font-size-18"></i> Añadir imágenes a la galería del producto
                                        </a>
                                    </b-alert>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Tiendas">
                                <b-card-text>
                                    <StoreTabPartial v-model="product.stores" :stores="stores" :product_slug="product.slug" :v="$v" :submitted="submitted"></StoreTabPartial> 
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Avanzado" title-item-class="mb-2">
                                <b-card-text>
                                    <AdvancedTabPartial v-model="product" :v="$v" :submitted="submitted"></AdvancedTabPartial> 
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Astun" title-item-class="mb-2" v-if="product?.integration?.slug === 'astun'">
                                <b-card-text>
                                    <AstunTabPartial v-model="product" :v="$v" :submitted="submitted"></AstunTabPartial> 
                                </b-card-text>
                            </b-tab>
                        </b-tabs> 
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>