<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Status from "@/helpers/general/status";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editor: ClassicEditor,
            product_status: Status.getList('products'),
            catalog_visibilities: Status.getList('catalog_visibilities'),
        }
    },
    components: {        
        ckeditor: CKEditor.component,
    },
    methods: {
        
    },
};
</script>

<template>
    <div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">General</h4>
                <p class="card-title-desc">
                    Ingresa los datos generales del producto
                </p>
                <div class="row">
                    <div class="form-group col-12">
                        <label for="name">Nombre</label>
                        <input id="name" v-model="value.name" type="text" class="form-control" placeholder="Ingresa un nombre"
                            :class="{ 'is-invalid': v.product.name.$error && submitted }" />
                        <div v-if="!v.product.name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
                    </div>
                    <div class="form-group col-12">
                        <label for="slug">Slug</label>
                        <p class="text-muted font-13">El «slug» es la versión amigable de la URL para el nombre. Suele estar todo en minúsculas y contiene solo letras, números y guiones.</p>
                        <input id="slug" v-model="value.slug" type="text" class="form-control" placeholder="Ingresa un slug"
                        :class="{ 'is-invalid': v.product.slug.$error && submitted }" />
                        <div v-if="v.product.slug.$error && submitted" class="invalid-feedback">Slug inválido</div>
                    </div>
                    <div class="form-group col-12">
                        <label for="status">Estado</label>
                        <select v-model="value.status" class="form-control"
                            :class="{ 'is-invalid': v.product.status.$error && submitted }">
                            <option v-for="option in product_status" v-bind:value="option.id" :key="option.id">
                                {{ option.label }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-12">
                        <label for="catalog_visibility">Visibilidad catálogo</label>
                        <p class="text-muted font-13">Este ajuste determina qué productos de las páginas de la tienda se mostrarán.</p>
                        <select v-model="value.catalog_visibility" class="form-control"
                            :class="{ 'is-invalid': v.product.catalog_visibility.$error && submitted }">
                            <option v-for="option in catalog_visibilities" v-bind:value="option.id" :key="option.id">
                                {{ option.label }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-12">
                        <label for="featured">Destacar producto?</label>
                        <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                v-model="value.featured"
                                class="custom-control-input"
                                id="featured"
                                :class="{ 'is-invalid': v.product.featured.$error && submitted }"
                            />
                            <label class="custom-control-label" for="featured">Este es un producto destacado</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Descripción del producto</h4>
                <div class="row">
                    <div class="form-group col-12">
                        <ckeditor id="description" v-model="value.description" :editor="editor"></ckeditor>
                    </div>
                </div>                        
            </div>
        </div>
        <div class="card mb-0">
            <div class="card-body">
                <h4 class="card-title">Descripción corta del producto</h4>
                <div class="row">
                    <div class="form-group col-12">
                        <ckeditor id="short_description" v-model="value.short_description" :editor="editor"></ckeditor>
                    </div>
                </div>                        
            </div>
        </div>
    </div>
</template>