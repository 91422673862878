<script>
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
export default {
    components: {
        SelectAutocompleteField     
    },
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            params:{
                integration_slug: 'astun',
            }
        }
    },
    methods: {
        insuranceChanged(product) {
            this.value.product_metadata.qlk_commerce_insurance_product.name = product?.name;
            this.value.product_metadata.qlk_commerce_insurance_product.price = product?.sale_price;
        },
        cardChanged(product) {
            this.value.product_metadata.qlk_commerce_card_product.name = product?.name;
            this.value.product_metadata.qlk_commerce_card_product.price = product?.sale_price;
        }
    },
};
</script>

<template>
    <div>
        <div class="row">
            <div class="form-group col-12">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        v-model="value.product_metadata.qlk_commerce_is_forfait"
                        class="custom-control-input"
                        id="qlk_commerce_is_forfait"
                    />
                    <label class="custom-control-label" for="qlk_commerce_is_forfait">Este es un producto forfait</label>
                </div>
            </div>
            <div class="form-group col-12">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        v-model="value.product_metadata.qlk_commerce_forfait_required_date"
                        class="custom-control-input"
                        id="qlk_commerce_forfait_required_date"
                    />
                    <label class="custom-control-label" for="qlk_commerce_forfait_required_date">Requiere fecha</label>
                </div>
            </div>
            <div class="form-group col-12">
                <label>Producto con precio de cupón</label>
                <p class="text-muted font-13">Si quieres aplicar un cupón, debes seleccionar el producto que contiene el precio con descuento.</p>
                <SelectAutocompleteField :url="`/products`" :field="'remote_product_id'" :params="params" :placeholder="'Seleccione el producto'" v-model="value.product_metadata.qlk_commerce_product_with_cupon_id"></SelectAutocompleteField>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-4">
                <label>Tarjeta</label>
                <SelectAutocompleteField
                    :url="`/products`"
                    :field="'remote_product_id'"
                    :params="params"
                    :placeholder="'Seleccione el producto'"
                    v-model="value.product_metadata.qlk_commerce_card_product.id"
                    @change="cardChanged"
                ></SelectAutocompleteField>
            </div>
            <div class="form-group col-4" v-if="value.product_metadata.qlk_commerce_card_product.id">
                <label for="qlk_commerce_card_product_name">Nombre</label>
                <input
                    id="qlk_commerce_card_product_name"
                    v-model="value.product_metadata.qlk_commerce_card_product.name"
                    type="text"
                    class="form-control"
                    placeholder="nombre"
                />
            </div>                       
            <div class="form-group col-4" v-if="value.product_metadata.qlk_commerce_card_product.id">
                <label for="qlk_commerce_card_product_price">Precio</label>
                <input
                    id="qlk_commerce_card_product_price"
                    v-model="value.product_metadata.qlk_commerce_card_product.price"
                    type="text"
                    class="form-control"
                    placeholder="precio"
                />
            </div>                    
        </div>
        <div class="row">
            <div class="form-group col-4">
                <label>Seguro</label>
                <SelectAutocompleteField
                    :url="`/products`"
                    :field="'remote_product_id'"
                    :params="params"
                    :placeholder="'Seleccione el producto'"
                    v-model="value.product_metadata.qlk_commerce_insurance_product.id"
                    @change="insuranceChanged"
                ></SelectAutocompleteField>
            </div>
            <div class="form-group col-4" v-if="value.product_metadata.qlk_commerce_insurance_product.id">
                <label for="qlk_commerce_insurance_product_name">Nombre</label>
                <input
                    id="qlk_commerce_insurance_product_name"
                    v-model="value.product_metadata.qlk_commerce_insurance_product.name"
                    type="text"
                    class="form-control"
                    placeholder="nombre"
                />
            </div>                       
            <div class="form-group col-4" v-if="value.product_metadata.qlk_commerce_insurance_product.id">
                <label for="qlk_commerce_insurance_product_price">Precio</label>
                <input
                    id="qlk_commerce_insurance_product_price"
                    v-model="value.product_metadata.qlk_commerce_insurance_product.price"
                    type="text"
                    class="form-control"
                    placeholder="precio"
                />
            </div>   
        </div>
    </div>
</template>