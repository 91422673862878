const daysOfWeek = [
    { id:1, label:'Monday' },
    { id:2, label:'Tuesday' },
    { id:3, label:'Wednesday' },
    { id:4, label:'Thursday' },
    { id:5, label:'Friday' },
    { id:6, label:'Saturday' },
    { id:7, label:'Sunday' }

]

export default {
    getDayOfweek(dayOfWeek) {
        const item = daysOfWeek?.find(({id}) => id == dayOfWeek);
        return item?.label;
    },
    getDaysOfweek() {
        return daysOfWeek;
    }
};